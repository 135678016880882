import { applyMiddleware, compose, createStore } from "redux";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import rootReducer from './reducers/rootReducer';


const composeEnhancers = ( window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace : true,
    traceLimit : 100
}) ) || compose

const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(
        thunk,
        promise
    ),
    window.REDUX_DEVTOOLS_EXTENSION ? window.devToolsExtension() : f => f,
)(createStore)

export default function configureStore(){
    const store = createStoreWithMiddleware(rootReducer);
    return store;
}