import {
    GET_SEARCH_LIST_START,
    GET_SEARCH_LIST_SUCCESS,
    GET_SEARCH_LIST_FAILURE
} from 'actions/GetCommonActions/listTypes';
import { updateObject } from '../utility';

const initialState = {
    searchList: [],
    bikeBrandList:[],
    bikesList:[],
    carBrandsList:[],
    carList:[],
    error: null,
    searchLoading: false
};
 
const getSearchListStart=( state )=>{
    return updateObject( state , { 
        error: null, 
        searchLoading: true  
    });
}
const getSearchListSuccess = ( state, action ) =>{
    return updateObject(state, {
        searchList: action.data,
        bikeBrandList: action.data.bike_brands,
        bikesList: action.data.bikes,
        carBrandsList: action.data.car_brands,
        carList: action.data.cars,
        error: null,
        searchLoading: false
    });
}
const getSearchListFail = (state, action)=>{
    return updateObject (state, {
        error: action.error,
        searchLoading: false
    });
}

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_SEARCH_LIST_START : return getSearchListStart( state, action );
        case GET_SEARCH_LIST_SUCCESS : return getSearchListSuccess( state, action );
        case GET_SEARCH_LIST_FAILURE : return getSearchListFail( state, action );
        
        default:
            return state;
    }
};

export default reducer;
