import {
        GET_SEARCH_USED_CAR_LIST_START,
        GET_SEARCH_USED_CAR_LIST_SUCCESS,
        GET_SEARCH_USED_CAR_LIST_UPDATE,
        GET_SEARCH_USED_CAR_LIST_FAILURE,

        GET_SEARCH_USED_BIKE_LIST_START,
        GET_SEARCH_USED_BIKE_LIST_SUCCESS,
        GET_SEARCH_USED_BIKE_LIST_UPDATE,
        GET_SEARCH_USED_BIKE_LIST_FAILURE
} from 'actions/SearchUsedVehicleActions/listTypes';

import { updateObject } from '../utility';
 
const initialState = {
    searchVehicleData: [],
    filterSortBy:[],
    vehicleListData:[],
    filterBodyType: [],
    filterBrandModal: [],
    filterBudget: [],
    filterColorType: [],
    filterFuelType: [],
    filterKilometer: [],
    filterOwner: [],
    filterTransmission: [],
    filterYears: [],
    error: null,
    loading: false
};
 
// SEARCH VEHICLE [CAR] : REDUCER FUNCTION
const getUsedFilterCarStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getUsedFilterCarSuccess = ( state, action ) => {
    return updateObject( state, {
        searchVehicleData : action.data,
        vehicleListData: action.data.data,
        filterSortBy: action.data.sortBy,
        filterBodyType : action.data.bodyType,
        filterBrandModal : action.data.brandAndModal,
        filterBudget : action.data.budget,
        filterColorType : action.data.colorType,
        filterFuelType : action.data.fuelType,
        filterKilometer : action.data.kilometer,
        filterOwner : action.data.owner,
        filterTransmission : action.data.transmission,
        filterYears : action.data.years,
        error: null,
        loading: false
    });
};

// 
// UPDATE STATE
const getUsedFilterCarUpdate = ( state, action ) => {
    return updateObject( state, {
        searchVehicleData : action.data,
        vehicleListData: action.data.data,
        error: null,
        loading: false
    });
};

const getUsedFilterCarFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

// SEARCH VEHICLE [BIKE] : REDUCER FUNCTION
const getUsedFilterBikeStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getUsedFilterBikeSuccess = ( state, action ) => {
    return updateObject( state, {
        searchVehicleData : action.data,
        error: null,
        loading: false
    });
};

// UPDATE STATE
const getUsedFilterBikeUpdate = ( state, action ) => {
    return updateObject( state, {
        searchVehicleData : action.data,
        error: null,
        loading: false
    });
};

const getUsedFilterBikeFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

// This is a REDUCER, it's an example of 'PURE FUNCTION'
// It accepts Previous STATE and ACTION 
// and RETURNS Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_SEARCH_USED_CAR_LIST_START: return getUsedFilterCarStart( state, action );
        case GET_SEARCH_USED_CAR_LIST_SUCCESS: return getUsedFilterCarSuccess( state, action );
        case GET_SEARCH_USED_CAR_LIST_UPDATE: return getUsedFilterCarUpdate( state, action );
        case GET_SEARCH_USED_CAR_LIST_FAILURE: return getUsedFilterCarFail( state, action );

        case GET_SEARCH_USED_BIKE_LIST_START: return getUsedFilterBikeStart( state, action );
        case GET_SEARCH_USED_BIKE_LIST_SUCCESS: return getUsedFilterBikeSuccess( state, action );
        case GET_SEARCH_USED_BIKE_LIST_UPDATE: return getUsedFilterBikeUpdate( state, action );
        case GET_SEARCH_USED_BIKE_LIST_FAILURE: return getUsedFilterBikeFail( state, action );

        default:
            return state;
    }
};

export default reducer;
