import {
    GET_COMPARE_VEHICLE_CAR_LIST_START,
    GET_COMPARE_VEHICLE_CAR_LIST_SUCCESS,
    GET_COMPARE_VEHICLE_CAR_LIST_FAILURE,

    GET_COMPARE_VEHICLE_BIKE_LIST_START,
    GET_COMPARE_VEHICLE_BIKE_LIST_SUCCESS,
    GET_COMPARE_VEHICLE_BIKE_LIST_FAILURE,
} from 'actions/GetCommonActions/listTypes';
import { updateObject } from '../utility';

// getCompareListReducer  
const initialState = {
    compareVehicleList: [],
    error: null,
    loading: false
};
 
// Newly Launched Car Reducers Function
const getCompareCarListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getCompareCarListSuccess = ( state, action ) => {
    return updateObject( state, {
        compareVehicleList: action.data,
        error: null,
        loading: false
    } );
};

const getCompareCarListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// Newly Launched Bike Reducers Function
const getCompareBikeListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getCompareBikeListSuccess = ( state, action ) => {
    return updateObject( state, {
        compareVehicleList: action.data,
        error: null,
        loading: false
    } );
};

const getCompareBikeListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        
        case GET_COMPARE_VEHICLE_CAR_LIST_START: return getCompareCarListStart( state, action );
        case GET_COMPARE_VEHICLE_CAR_LIST_SUCCESS: return getCompareCarListSuccess( state, action );
        case GET_COMPARE_VEHICLE_CAR_LIST_FAILURE: return getCompareCarListFail( state, action );

        case GET_COMPARE_VEHICLE_BIKE_LIST_START: return getCompareBikeListStart( state, action );
        case GET_COMPARE_VEHICLE_BIKE_LIST_SUCCESS: return getCompareBikeListSuccess( state, action );
        case GET_COMPARE_VEHICLE_BIKE_LIST_FAILURE: return getCompareBikeListFail( state, action );

        default:
            return state;
    }
};

export default reducer;
