import {
    GET_DEALER_INFO_START,
    GET_DEALER_INFO_SUCCESS,
    GET_DEALER_INFO_FAILURE
} from 'actions/ProductDealerActions/listTypes';
import { updateObject } from '../utility';

// getCompareListReducer  
const initialState = {
    DealerInfoData :[],
    carData:[],
    error: null,
    loading: false,
};

// VEHICLE SHORTLIST REDUCER FUNCTION
const getDealerInfoStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getDealerInfoSuccess = ( state, action ) => {
    return updateObject( state, {
        DealerInfoData : action.data.dealer_information,
        carData: action.data.cardata,
        error: null,
        loading: false
    } );
};

const getDealerInfoFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_DEALER_INFO_START: return getDealerInfoStart( state, action );
        case GET_DEALER_INFO_SUCCESS: return getDealerInfoSuccess( state, action );
        case GET_DEALER_INFO_FAILURE: return getDealerInfoFail( state, action );

        default:
            return state;
    }
};

export default reducer;
