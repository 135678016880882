import {
    GET_SIMILAR_CAR_LIST_START,
    GET_SIMILAR_CAR_LIST_SUCCESS,
    GET_SIMILAR_CAR_LIST_FAILURE,

    GET_SIMILAR_BIKE_LIST_START,
    GET_SIMILAR_BIKE_LIST_SUCCESS,
    GET_SIMILAR_BIKE_LIST_FAILURE,
} from 'actions/GetCommonActions/listTypes';
import { updateObject } from '../utility';


const initialState = {
    similarVehicleList: [],
    error: null,
    loading: false
};
 
// SIMILAR CAR LIST FUNCTION
const getSimilarCarListStart = ( state ) => {
    return updateObject( state, 
        { 
            error: null,
            loading: true
        } );
};

const getSimilarCarListSuccess = ( state, action ) => {
    return updateObject( state, {
        similarVehicleList: action.data,
        error: null,
        loading: false
    } );
};

const getSimilarCarListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// SIMILAR BIKE LIST FUNCTION
const getSimilarBikeListStart = ( state ) => {
    return updateObject( state, 
        {
            error: null,
            loading: true
        } );
};

const getSimilarBikeListSuccess = ( state, action ) => {
    return updateObject( state, {
        similarVehicleList: action.data,
        error: null,
        loading: false
    } );
};

const getSimilarBikeListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_SIMILAR_CAR_LIST_START: return getSimilarCarListStart( state, action );
        case GET_SIMILAR_CAR_LIST_SUCCESS: return getSimilarCarListSuccess( state, action );
        case GET_SIMILAR_CAR_LIST_FAILURE: return getSimilarCarListFail( state, action );

        case GET_SIMILAR_BIKE_LIST_START: return getSimilarBikeListStart( state, action );
        case GET_SIMILAR_BIKE_LIST_SUCCESS: return getSimilarBikeListSuccess( state, action );
        case GET_SIMILAR_BIKE_LIST_FAILURE: return getSimilarBikeListFail( state, action );

        default:
            return state;
    }
};

export default reducer;
