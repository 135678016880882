import {
    COMPARE_PRODUCT_DETAIL_DATA_START,
    COMPARE_PRODUCT_DETAIL_DATA_SUCCESS,
    COMPARE_PRODUCT_DETAIL_DATA_FAILURE,
    COMPARE_PRODUCT_DETAIL_COLLAPSE,
    COMPARE_PRODUCT_UPDATE_COLLAPSE
} from 'actions/CompareVehicleActions/listTypes';
import { updateObject } from '../utility';

// getCompareListReducer  
const initialState = {
    compareProductDetailData:[],
    compareProductDetails:[],
    compareProductOtherDetails:[],
    error: null,
    loading: false,
};
 
// Car Detail Reducers Function
const compareProductDetailDataStart = ( state ) => {
    return updateObject( state,
        {
            error: null,
            loading: true
        });
};

const compareProductDetailDataSuccess = ( state, action ) => {
    return updateObject( state, {
        compareProductDetailData : action.data,
        compareProductDetails : action.data.details,
        compareProductOtherDetails : action.data.other_details,
        error: null,
        loading: false
    } );
};

const compareProductDetailDataFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

const compareProductDetailCollapse= ( state, action ) => {
    let newCompareProductOtherDetails=state.compareProductOtherDetails;
    newCompareProductOtherDetails[action.itemIndex].isCollapseOpen= !action.data.isCollapseOpen;
    return updateObject( state, {
        compareProductOtherDetails :newCompareProductOtherDetails
    } );
};

const compareProductUpdateCollapse= ( state, action ) => {
    return updateObject( state, {
        compareProductDetails :action.data
    } );
};


// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case COMPARE_PRODUCT_DETAIL_DATA_START: return compareProductDetailDataStart( state, action );
        case COMPARE_PRODUCT_DETAIL_DATA_SUCCESS: return compareProductDetailDataSuccess( state, action );
        case COMPARE_PRODUCT_DETAIL_DATA_FAILURE: return compareProductDetailDataFail( state, action ); 
        case COMPARE_PRODUCT_DETAIL_COLLAPSE: return compareProductDetailCollapse( state, action );
        case COMPARE_PRODUCT_UPDATE_COLLAPSE: return compareProductUpdateCollapse( state, action );

        default:
            return state;
    }
};

export default reducer;
