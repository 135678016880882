import {
    COMPARE_SUV_VEHICLE_LIST_START,
    COMPARE_SUV_VEHICLE_LIST_SUCCESS,
    COMPARE_SUV_VEHICLE_LIST_FAILURE,

    COMPARE_SEDAN_VEHICLE_LIST_START,
    COMPARE_SEDAN_VEHICLE_LIST_SUCCESS, 
    COMPARE_SEDAN_VEHICLE_LIST_FAILURE,
    
    COMPARE_HATCHBACK_VEHICLE_LIST_START,
    COMPARE_HATCHBACK_VEHICLE_LIST_SUCCESS,
    COMPARE_HATCHBACK_VEHICLE_LIST_FAILURE,
     
    COMPARE_SCOOTER_VEHICLE_LIST_START,
    COMPARE_SCOOTER_VEHICLE_LIST_SUCCESS,
    COMPARE_SCOOTER_VEHICLE_LIST_FAILURE, 
    
    COMPARE_SPORTS_VEHICLE_LIST_START,
    COMPARE_SPORTS_VEHICLE_LIST_SUCCESS,
    COMPARE_SPORTS_VEHICLE_LIST_FAILURE,
    
    COMPARE_COMMUTER_VEHICLE_LIST_SUCCESS,
    COMPARE_COMMUTER_VEHICLE_LIST_FAILURE,
    COMPARE_COMMUTER_VEHICLE_LIST_START,
    
    MODAL_VARIANT_LIST_START,
    MODAL_VARIANT_LIST_SUCCESS,
    MODAL_VARIANT_LIST_FAILURE,

    COMPARE_PRODUCT_DETAIL_START,
    COMPARE_PRODUCT_DETAIL_SUCCESS,
    COMPARE_PRODUCT_DETAIL_FAILURE

} from 'actions/CompareVehicleActions/listTypes';
import { updateObject } from '../utility';

// getCompareListReducer  
const initialState = {
    productDetailData:[],
    compareSuvData:[],
    compareSedanData:[],
    compareHatchbackData:[],
    compareScooterData:[],
    compareSportsData:[],
    compareCommuterData:[],
    modalWiseVariantListData:[],
    error: null,
    loadingSuvData: false,
    loadingSedanData: false,
    loadingHatchbackData: false,
    loadingScooterData: false,
    loadingSportsData: false,
    loadingCommuterData: false,
    loadingModalData:false,
    loadingProductData:false
};
 
// Car Detail Reducers Function
const compareSuvVehicleStart = ( state ) => {
    return updateObject( state, { error: null, loadingSuvData: true } );
};

const compareSuvVehicleSuccess = ( state, action ) => {
    return updateObject( state, {
        compareSuvData : action.data,
        error: null,
        loadingSuvData: false
    } );
};

const compareSuvVehicleFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingSuvData: false
    } );
};

const compareSedanVehicleStart = ( state ) => {
    return updateObject( state, { error: null, loadingSedanData: true } );
};

const compareSedanVehicleSuccess = ( state, action ) => {
    return updateObject( state, {
        compareSedanData: action.data,
        error: null,
        loadingSedanData: false
    } );
};

const compareSedanVehicleFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingSedanData: false
    } );
};

const compareHatchbackVehicleStart = ( state ) => {
    return updateObject( state, { error: null, loadingHatchbackData: true } );
};

const compareHatchbackVehicleSuccess = ( state, action ) => {
    return updateObject( state, {
        compareHatchbackData : action.data,
        error: null,
        loadingHatchbackData: false
    } );
};

const compareHatchbackVehicleFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingHatchbackData: false
    } );
};

const compareScooterVehicleStart = ( state ) => {
    return updateObject( state, { error: null, loadingScooterData: true } );
};

const compareScooterVehicleSuccess = ( state, action ) => {
    return updateObject( state, {
        compareScooterData : action.data,
        error: null,
        loadingScooterData: false
    } );
};

const compareScooterVehicleFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingScooterData: false
    } );
};

const compareSportsVehicleStart = ( state ) => {
    return updateObject( state, { error: null, loadingSportsData: true } );
};

const compareSportsVehicleSuccess = ( state, action ) => {
    return updateObject( state, {
        compareSportsData : action.data,
        error: null,
        loadingSportsData: false
    } );
};

const compareSportsVehicleFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingSportsData: false
    } );
};

const compareCommuterVehicleStart = ( state ) => {
    return updateObject( state, { error: null, loadingCommuterData: true } );
};

const compareCommuterVehicleSuccess = ( state, action ) => {
    return updateObject( state, {
        compareCommuterData : action.data,
        error: null,
        loadingCommuterData: false
    } );
};

const compareCommuterVehicleFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingCommuterData: false
    } );
};

const modalVariantListStart = ( state ) => {
    return updateObject( state, { error: null, loadingModalData: true } );
};

const modalVariantListSuccess = ( state, action ) => {
    return updateObject( state, {
        modalWiseVariantListData : action.data.data,
        error: null,
        loadingModalData: false
    } );
};

const modalVariantListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingModalData: false
    } );
};
// 
const compareProductDetailStart = ( state ) => {
    return updateObject( state, { 
            error: null,
            loadingProductData: true
        } );
};

const compareProductDetailSuccess = ( state, action ) => {
    return updateObject( state, {
        productDetailData : action.data,
        error: null,
        loadingProductData: false
    } );
};

const compareProductDetailFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingProductData: false
    } );
};

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case COMPARE_SUV_VEHICLE_LIST_START: return compareSuvVehicleStart( state, action );
        case COMPARE_SUV_VEHICLE_LIST_SUCCESS: return compareSuvVehicleSuccess( state, action );
        case COMPARE_SUV_VEHICLE_LIST_FAILURE: return compareSuvVehicleFail( state, action );

        case COMPARE_SEDAN_VEHICLE_LIST_START: return compareSedanVehicleStart( state, action );
        case COMPARE_SEDAN_VEHICLE_LIST_SUCCESS: return compareSedanVehicleSuccess( state, action );
        case COMPARE_SEDAN_VEHICLE_LIST_FAILURE: return compareSedanVehicleFail( state, action );

        case COMPARE_HATCHBACK_VEHICLE_LIST_START: return compareHatchbackVehicleStart( state, action );
        case COMPARE_HATCHBACK_VEHICLE_LIST_SUCCESS: return compareHatchbackVehicleSuccess( state, action );
        case COMPARE_HATCHBACK_VEHICLE_LIST_FAILURE: return compareHatchbackVehicleFail( state, action );

        case COMPARE_SCOOTER_VEHICLE_LIST_START: return compareScooterVehicleStart( state, action );
        case COMPARE_SCOOTER_VEHICLE_LIST_SUCCESS: return compareScooterVehicleSuccess( state, action );
        case COMPARE_SCOOTER_VEHICLE_LIST_FAILURE: return compareScooterVehicleFail( state, action );

        case COMPARE_SPORTS_VEHICLE_LIST_START: return compareSportsVehicleStart( state, action );
        case COMPARE_SPORTS_VEHICLE_LIST_SUCCESS: return compareSportsVehicleSuccess( state, action );
        case COMPARE_SPORTS_VEHICLE_LIST_FAILURE: return compareSportsVehicleFail( state, action );

        case COMPARE_COMMUTER_VEHICLE_LIST_START: return compareCommuterVehicleStart( state, action );
        case COMPARE_COMMUTER_VEHICLE_LIST_SUCCESS: return compareCommuterVehicleSuccess( state, action );
        case COMPARE_COMMUTER_VEHICLE_LIST_FAILURE: return compareCommuterVehicleFail( state, action );

        case MODAL_VARIANT_LIST_START: return modalVariantListStart( state, action );
        case MODAL_VARIANT_LIST_SUCCESS: return modalVariantListSuccess( state, action );
        case MODAL_VARIANT_LIST_FAILURE: return modalVariantListFail( state, action );  

        case COMPARE_PRODUCT_DETAIL_START: return compareProductDetailStart( state, action );
        case COMPARE_PRODUCT_DETAIL_SUCCESS: return compareProductDetailSuccess( state, action );
        case COMPARE_PRODUCT_DETAIL_FAILURE: return compareProductDetailFail( state, action );  

        default:
            return state;
    }
};

export default reducer;
