import {
    
    GET_BRAND_DETAIL_CAR_LIST_START, 
    GET_BRAND_DETAIL_CAR_LIST_SUCCESS, 
    GET_BRAND_DETAIL_CAR_LIST_FAILURE,

    GET_BRAND_DETAIL_BIKE_LIST_START,
    GET_BRAND_DETAIL_BIKE_LIST_SUCCESS,
    GET_BRAND_DETAIL_BIKE_LIST_FAILURE
} from 'actions/VehicleBrandActions/listTypes';

import { updateObject } from '../utility';
  
const initialState = {
    brandDetailData:[],
    brandOngoing:[],
    brandExpired:[],
    brandUpcoming:[],
    brandOngoingCar:[],
    brandExpiredCar:[],
    brandUpcomingCar:[],
    brandOngoingBike:[],
    brandExpiredBike:[],
    brandUpcomingBike:[],
    error: null,
    loading: false,
    loadingBrandBike: false,
};

const getBrandDetailCarListStart = ( state ) => {
    return updateObject( state, { error: null, loadingBrandCar: true } );
};

const getBrandDetailCarListSuccess = ( state, action ) => {
    return updateObject( state, {
        brandDetailData : action.data,
        brandOngoing:action.data.ongoing,
        brandExpired:action.data.expired,
        brandUpcoming:action.data.upcoming,
        // brandOngoingCar : action.data.ongoing,
        // brandExpiredCar : action.data.expired,
        // brandUpcomingCar : action.data.upcoming,
        error: null,
        loading: false
    } );
};

const getBrandDetailCarListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// Bike get Brand Detail Reducers Function
const getBrandDetailBikeListStart = ( state ) => {
    return updateObject( state, {
            error: null,
            loading: true
        } );
};

const getBrandDetailBikeListSuccess = ( state, action ) => {
    return updateObject( state, {
        brandDetailData: action.data,
        brandOngoing:action.data.ongoing,
        brandExpired:action.data.expired,
        brandUpcoming:action.data.upcoming,
        // brandOngoingBike : action.data.ongoing,
        // brandExpiredBike : action.data.expired,
        // brandUpcomingBike : action.data.upcoming,
        error: null,
        loadingBrandBike: false
    } );
};

const getBrandDetailBikeListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingBrandBike: false
    } );
};

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        
        case GET_BRAND_DETAIL_CAR_LIST_START: return getBrandDetailCarListStart( state, action );
        case GET_BRAND_DETAIL_CAR_LIST_SUCCESS: return getBrandDetailCarListSuccess( state, action );
        case GET_BRAND_DETAIL_CAR_LIST_FAILURE: return getBrandDetailCarListFail( state, action );
        
        case GET_BRAND_DETAIL_BIKE_LIST_START: return getBrandDetailBikeListStart( state, action );
        case GET_BRAND_DETAIL_BIKE_LIST_SUCCESS: return getBrandDetailBikeListSuccess( state, action );
        case GET_BRAND_DETAIL_BIKE_LIST_FAILURE: return getBrandDetailBikeListFail( state, action );

        default:
            return state;
    }
};

export default reducer;
