import {
        GET_ASSISTANCE_START,
        GET_ASSISTANCE_SUCCESS,
        GET_ASSISTANCE_FAILURE
} from 'actions/ProductDealerActions/listTypes';
import { updateObject } from '../utility';

const initialState = {
    assistanceResponseData :[],
    error: null,
    loading: false,
};

// VEHICLE REDUCER FUNCTION
const getAssistanceStart = ( state ) => {
    return updateObject( state,{ 
                error: null,
                loading: true
            });
};

const getAssistanceSuccess = ( state, action ) => {
    return updateObject( state, {
        assistanceResponseData : action.data,
        error: null,
        loading: false
    } );
};

const getAssistanceFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        
        case GET_ASSISTANCE_START: return getAssistanceStart( state, action );
        case GET_ASSISTANCE_SUCCESS: return getAssistanceSuccess( state, action );
        case GET_ASSISTANCE_FAILURE: return getAssistanceFail( state, action );

        default:
            return state;
    }
};

export default reducer;
