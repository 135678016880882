import React from 'react';
import { LoaderContainer } from './style';
import loaderImage from 'assets/png/loader.gif'

const Loader = () => {
    return (
        <LoaderContainer className="d-flex justify-content-center">
            <div id="loader" className="center">
                <div className="loader-wrap">
                    <img src={loaderImage} alt="Loader" className="page-load-img"/>
                </div>
            </div>
        </LoaderContainer>
    )
}

export default Loader;