// VEHICLE BRAND LIST "ONGOING | UPCOMING | EXPIRED" API LIST CONSTANTS 
export const GET_TYPE_CAR_BRAND_LIST_START = 'GET_TYPE_CAR_BRAND_LIST_START';
export const GET_TYPE_CAR_BRAND_LIST_SUCCESS = 'GET_TYPE_CAR_BRAND_LIST_SUCCESS';
export const GET_TYPE_CAR_BRAND_LIST_FAILURE = 'GET_TYPE_CAR_BRAND_LIST_FAILURE';

export const GET_TYPE_BIKE_BRAND_LIST_START = 'GET_TYPE_BIKE_BRAND_LIST_START';
export const GET_TYPE_BIKE_BRAND_LIST_SUCCESS = 'GET_TYPE_BIKE_BRAND_LIST_SUCCESS';
export const GET_TYPE_BIKE_BRAND_LIST_FAILURE = 'GET_TYPE_BIKE_BRAND_LIST_FAILURE';
// VEHICLE BRAND LIST "ONGOING | UPCOMING | EXPIRED" API LIST CONSTANTS END

// VEHICLE BRAND DETAIL "ONGOING | UPCOMING | EXPIRED" API LIST CONSTANTS 
export const GET_BRAND_DETAIL_CAR_LIST_START = 'GET_BRAND_DETAIL_CAR_LIST_START';
export const GET_BRAND_DETAIL_CAR_LIST_SUCCESS = 'GET_BRAND_DETAIL_CAR_LIST_SUCCESS';
export const GET_BRAND_DETAIL_CAR_LIST_FAILURE = 'GET_BRAND_DETAIL_CAR_LIST_FAILURE';

export const GET_BRAND_DETAIL_BIKE_LIST_START = 'GET_BRAND_DETAIL_BIKE_LIST_START';
export const GET_BRAND_DETAIL_BIKE_LIST_SUCCESS = 'GET_BRAND_DETAIL_BIKE_LIST_SUCCESS';
export const GET_BRAND_DETAIL_BIKE_LIST_FAILURE = 'GET_BRAND_DETAIL_BIKE_LIST_FAILURE';
// VEHICLE BRAND DETAIL "ONGOING | UPCOMING | EXPIRED" API LIST CONSTANTS END