import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    .spinner-grow {
        background: #a9acbe !important;
        width: 10px !important;
        height: 10px !important;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    
        
        .Toastify__close-button {
            color: #ffff;
            &:focus {
                outline: none;
            }
        }
    }
    
`

