import {
    GET_CITY_LIST_START,
    GET_CITY_LIST_SUCCESS,
    GET_CITY_LIST_FAILURE
    
} from 'actions/GetCommonActions/listTypes';
import { updateObject } from '../utility';

const initialState = {
    cityList: [],
    myLocation:[],
    error: null,
    loading: false
};
 
const getCityListStart=( state )=>{
    return updateObject( state , { 
        error: null, 
        loading: true  
    });
}
const getCityListSuccess = ( state, action ) =>{
    return updateObject(state, {
        cityList: action.data,
        error: null,
        loading: false
    });
}
const getCityListFail = (state, action)=>{
    return updateObject (state, {
        error: action.error,
        loading: false
    });
}

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updated STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case GET_CITY_LIST_START : return getCityListStart( state, action );
        case GET_CITY_LIST_SUCCESS : return getCityListSuccess( state, action );
        case GET_CITY_LIST_FAILURE : return getCityListFail( state, action );
        default:
            return state;
    }
};

export default reducer;
