import {
    GET_TYPE_CAR_BRAND_LIST_START,
    GET_TYPE_CAR_BRAND_LIST_SUCCESS,
    GET_TYPE_CAR_BRAND_LIST_FAILURE,
    
    GET_TYPE_BIKE_BRAND_LIST_START,
    GET_TYPE_BIKE_BRAND_LIST_SUCCESS,
    GET_TYPE_BIKE_BRAND_LIST_FAILURE,

} from 'actions/VehicleBrandActions/listTypes';

import { updateObject } from '../utility';
  
const initialState = {
    vehicleBrandData:[],
    ongoingProduct:[],
    upcomingProduct:[],
    expiredProduct:[],
    loading: false,
    error: null,
};
 
// Car Detail Reducers Function
const getTypeCarBrandListStart = ( state ) => {
    return updateObject( state,{ 
                error: null,
                loading: true
            });
};

const getTypeCarBrandListSuccess = ( state, action ) => {
    return updateObject( state, {
        vehicleBrandData : action.data,
        ongoingProduct: action.data.ongoing,
        upcomingProduct: action.data.upcoming,
        expiredProduct: action.data.expired,
        error: null,
        loading: false
    } );
};

const getTypeCarBrandListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// Bike Detail Reducers Function
const getTypeBikeBrandListStart = ( state ) => {
    return updateObject( state, {
            error: null,
            loading: true
        } );
};

const getTypeBikeBrandListSuccess = ( state, action ) => {
    return updateObject( state, {
        vehicleBrandData: action.data,
        ongoingProduct: action.data.ongoing,
        upcomingProduct: action.data.upcoming,
        expiredProduct: action.data.expired,
        error: null,
        loading: false
    } );
};

const getTypeBikeBrandListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_TYPE_CAR_BRAND_LIST_START: return getTypeCarBrandListStart( state, action );
        case GET_TYPE_CAR_BRAND_LIST_SUCCESS: return getTypeCarBrandListSuccess( state, action );
        case GET_TYPE_CAR_BRAND_LIST_FAILURE: return getTypeCarBrandListFail( state, action );

        case GET_TYPE_BIKE_BRAND_LIST_START: return getTypeBikeBrandListStart( state, action );
        case GET_TYPE_BIKE_BRAND_LIST_SUCCESS: return getTypeBikeBrandListSuccess( state, action );
        case GET_TYPE_BIKE_BRAND_LIST_FAILURE: return getTypeBikeBrandListFail( state, action );
        
        default:
            return state;
    }
};

export default reducer;
