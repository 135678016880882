import {
    GET_BRAND_MODAL_CAR_LIST_START,
    GET_BRAND_MODAL_CAR_LIST_SUCCESS,
    GET_BRAND_MODAL_CAR_LIST_FAILURE,

    GET_BRAND_MODAL_BIKE_LIST_START,
    GET_BRAND_MODAL_BIKE_LIST_SUCCESS,
    GET_BRAND_MODAL_BIKE_LIST_FAILURE,
} from 'actions/GetCommonActions/listTypes';
import { updateObject } from '../utility';

const initialState = {
    brandModalList: [],
    error: null,
    loading: false
};

const getBrandModalCarListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getBrandModalCarListSuccess = ( state, action ) => {
    return updateObject( state, {
        brandModalList: action.data,
        error: null,
        loading: false
    } );
};

const getBrandModalCarListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// BIKE LIST FUNCTION
const getBrandModalBikeListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getBrandModalBikeListSuccess = ( state, action ) => {
    return updateObject( state, {
        brandModalList: action.data,
        error: null,
        loading: false
    } );
};

const getBrandModalBikeListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
    
        case GET_BRAND_MODAL_CAR_LIST_START: return getBrandModalCarListStart( state, action );
        case GET_BRAND_MODAL_CAR_LIST_SUCCESS: return getBrandModalCarListSuccess( state, action );
        case GET_BRAND_MODAL_CAR_LIST_FAILURE: return getBrandModalCarListFail( state, action );

        case GET_BRAND_MODAL_BIKE_LIST_START: return getBrandModalBikeListStart( state, action );
        case GET_BRAND_MODAL_BIKE_LIST_SUCCESS: return getBrandModalBikeListSuccess( state, action );
        case GET_BRAND_MODAL_BIKE_LIST_FAILURE: return getBrandModalBikeListFail( state, action );

        default:
            return state;
    }
};

export default reducer;
