import {
    GET_PAPULAR_CAR_LIST_START,
    GET_PAPULAR_CAR_LIST_SUCCESS,
    GET_PAPULAR_CAR_LIST_FAILURE,

    GET_PAPULAR_BIKE_LIST_START,
    GET_PAPULAR_BIKE_LIST_SUCCESS,
    GET_PAPULAR_BIKE_LIST_FAILURE
} from 'actions/GetCommonActions/listTypes';
import { updateObject } from '../utility';


const initialState = {
    papularVehicleList: [],
    error: null,
    loading: false
};
 

const getPapularCarListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getPapularCarListSuccess = ( state, action ) => {
    return updateObject( state, {
        papularVehicleList: action.data,
        error: null,
        loading: false
    } );
};

const getPapularCarListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// BIKE LIST FUNCTION
const getPapularBikeListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getPapularBikeListSuccess = ( state, action ) => {
    return updateObject( state, {
        papularVehicleList: action.data,
        error: null,
        loading: false
    } );
};

const getPapularBikeListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};



const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_PAPULAR_CAR_LIST_START: return getPapularCarListStart( state, action );
        case GET_PAPULAR_CAR_LIST_SUCCESS: return getPapularCarListSuccess( state, action );
        case GET_PAPULAR_CAR_LIST_FAILURE: return getPapularCarListFail( state, action );

        case GET_PAPULAR_BIKE_LIST_START: return getPapularBikeListStart( state, action );
        case GET_PAPULAR_BIKE_LIST_SUCCESS: return getPapularBikeListSuccess( state, action );
        case GET_PAPULAR_BIKE_LIST_FAILURE: return getPapularBikeListFail( state, action );

        default:
            return state;
    }
};

export default reducer;
