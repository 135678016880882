import {
    GET_CAR_BRAND_LIST_START,
    GET_CAR_BRAND_LIST_SUCCESS,
    GET_CAR_BRAND_LIST_FAILURE,

    GET_BIKE_BRAND_LIST_START,
    GET_BIKE_BRAND_LIST_SUCCESS,
    GET_BIKE_BRAND_LIST_FAILURE,
} from 'actions/GetCommonActions/listTypes';
import { updateObject } from '../utility';

const initialState = {
    vehicleBrandList: [],
    error: null,
    loading: false
};
 
// CAR BRAND LIST REDUCER FUNCTION
const getCarBrandListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getCarBrandListSuccess = ( state, action ) => {
    return updateObject( state, {
        vehicleBrandList: action.data,
        error: null,
        loading: false
    } );
};

const getCarBrandListFailure = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// BIKE BRAND LIST REDUCER FUNCTION
const getBikeBrandListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getBikeBrandListSuccess = ( state, action ) => {
    return updateObject( state, {
        vehicleBrandList: action.data,
        error: null,
        loading: false
    } );
};

const getBikeBrandListFailure = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};



const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_CAR_BRAND_LIST_START: return getCarBrandListStart( state, action );
        case GET_CAR_BRAND_LIST_SUCCESS: return getCarBrandListSuccess( state, action );
        case GET_CAR_BRAND_LIST_FAILURE: return getCarBrandListFailure( state, action );

        case GET_BIKE_BRAND_LIST_START: return getBikeBrandListStart( state, action );
        case GET_BIKE_BRAND_LIST_SUCCESS: return getBikeBrandListSuccess( state, action );
        case GET_BIKE_BRAND_LIST_FAILURE: return getBikeBrandListFailure( state, action );

        default:
            return state;
    }
};

export default reducer;
