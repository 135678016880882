
import {
        GET_NEWLY_LAUNCHED_CAR_LIST_START,
        GET_NEWLY_LAUNCHED_CAR_LIST_SUCCESS,
        GET_NEWLY_LAUNCHED_CAR_LIST_FAILURE,

        GET_NEWLY_LAUNCHED_BIKE_LIST_START,
        GET_NEWLY_LAUNCHED_BIKE_LIST_SUCCESS,
        GET_NEWLY_LAUNCHED_BIKE_LIST_FAILURE,
} from 'actions/GetCommonActions/listTypes';
import { updateObject } from '../utility';


const initialState = {
    newlyLaunchedVehicleList: [],
    error: null,
    loading: false
};
 
// Newly Launched Car Reducers Function
const getNewlyLaunchedCarListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getNewlyLaunchedCarListSuccess = ( state, action ) => {
    return updateObject( state, {
        newlyLaunchedVehicleList: action.data,
        error: null,
        loading: false
    } );
};

const getNewlyLaunchedCarListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// Newly Launched Bike Reducers Function
const getNewlyLaunchedBikeListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getNewlyLaunchedBikeListSuccess = ( state, action ) => {
    return updateObject( state, {
        newlyLaunchedVehicleList: action.data,
        error: null,
        loading: false
    } );
};

const getNewlyLaunchedBikeListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        
        case GET_NEWLY_LAUNCHED_CAR_LIST_START: return getNewlyLaunchedCarListStart( state, action );
        case GET_NEWLY_LAUNCHED_CAR_LIST_SUCCESS: return getNewlyLaunchedCarListSuccess( state, action );
        case GET_NEWLY_LAUNCHED_CAR_LIST_FAILURE: return getNewlyLaunchedCarListFail( state, action );

        case GET_NEWLY_LAUNCHED_BIKE_LIST_START: return getNewlyLaunchedBikeListStart( state, action );
        case GET_NEWLY_LAUNCHED_BIKE_LIST_SUCCESS: return getNewlyLaunchedBikeListSuccess( state, action );
        case GET_NEWLY_LAUNCHED_BIKE_LIST_FAILURE: return getNewlyLaunchedBikeListFail( state, action );

        default:
            return state;
    }
};

export default reducer;
