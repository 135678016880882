import {
        ALL_REVIEW_START,
        ALL_REVIEW_SUCCESS,
        ALL_REVIEW_FAILURE
} from 'actions/ProductDealerActions/listTypes';
import { updateObject } from '../utility';

const initialState = {
    allReviewData :[],
    reviewData :[],
    error: null,
    loading: false,
};

// VEHICLE SHORTLIST REDUCER FUNCTION
const allReviewStart = ( state ) => {
    return updateObject( state,{ 
                error: null,
                loading: true
            });
};

const allReviewSuccess = ( state, action ) => {
    return updateObject( state, {
        allReviewData : action.data,
        reviewData : action.data.data,
        error: null,
        loading: false
    } );
};

const allReviewFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        
        case ALL_REVIEW_START: return allReviewStart( state, action );
        case ALL_REVIEW_SUCCESS: return allReviewSuccess( state, action );
        case ALL_REVIEW_FAILURE: return allReviewFail( state, action );

        default:
            return state;
    }
};

export default reducer;
