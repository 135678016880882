// DEALER: GET DEALER INFORMATION CONSTANTS START
export const GET_DEALER_INFO_START = 'GET_DEALER_INFO_START';
export const GET_DEALER_INFO_SUCCESS = 'GET_DEALER_INFO_SUCCESS';
export const GET_DEALER_INFO_FAILURE = 'GET_DEALER_INFO_FAILURE';
// DEALER: GET DEALER INFORMATION CONSTANTS END

// DEALER: GET NEW DEALER INFORMATION CONSTANTS START
export const GET_NEW_DEALER_INFO_START = 'GET_NEW_DEALER_INFO_START';
export const GET_NEW_DEALER_INFO_SUCCESS = 'GET_NEW_DEALER_INFO_SUCCESS';
export const GET_NEW_DEALER_INFO_FAILURE = 'GET_NEW_DEALER_INFO_FAILURE';
// DEALER: GET NEW DEALER INFORMATION CONSTANTS END

// DEALER: WRITE REVIEW CONSTANTS START
export const WRITE_REVIEW_START = 'WRITE_REVIEW_START';
export const WRITE_REVIEW_SUCCESS = 'WRITE_REVIEW_SUCCESS';
export const WRITE_REVIEW_FAILURE = 'WRITE_REVIEW_FAILURE';
// DEALER:WRITE REVIEW CONSTANTS END

// DEALER: ALL REVIEW CONSTANTS START
export const ALL_REVIEW_START = 'ALL_REVIEW_START';
export const ALL_REVIEW_SUCCESS = 'ALL_REVIEW_SUCCESS';
export const ALL_REVIEW_FAILURE = 'ALL_REVIEW_FAILURE';
// DEALER: ALL REVIEW CONSTANTS END

// DEALER: GET ASSISTANCE CONSTANTS START
export const GET_ASSISTANCE_START = 'GET_ASSISTANCE_START';
export const GET_ASSISTANCE_SUCCESS = 'GET_ASSISTANCE_SUCCESS';
export const GET_ASSISTANCE_FAILURE = 'GET_ASSISTANCE_FAILURE';
// DEALER: GET ASSISTANCE CONSTANTS END
