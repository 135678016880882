import {
    VEHICLE_SHORT_LIST_START,
    VEHICLE_SHORT_LIST_SUCCESS,
    VEHICLE_SHORT_LIST_FAILURE,

    ADD_REMOVE_VEHICLE_SHORTLIST_START, 
    ADD_REMOVE_VEHICLE_SHORTLIST_SUCCESS,
    ADD_REMOVE_VEHICLE_SHORTLIST_FAILURE
} from 'actions/ShortlistVehicleActions/listTypes';
import { updateObject } from '../utility';

// getCompareListReducer  
const initialState = {
    VehicleShortList :[],
    AddRemoveData :[],
    NewVehicleShortList :[],
    UsedVehicleShortList :[],
    error: null,
    loading: false,
};

// VEHICLE SHORTLIST REDUCER FUNCTION
const getVehicleShortListStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getVehicleShortListSuccess = ( state, action ) => {
    return updateObject( state, {
        VehicleShortList : action.data,
        NewVehicleShortList: action.data.new_vehicles,
        UsedVehicleShortList: action.data.old_vehicles,
        error: null,
        loading: false
    } );
};

const getVehicleShortListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// ADD REMOVE VEHICLE SHORTLIST REDUCER FUNCTION
const addRemoveShortlistStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const addRemoveShortlistSuccess = ( state, action ) => {
    return updateObject( state, {
        AddRemoveData : action.data,
        error: null,
        loading: false
    } );
};

const addRemoveShortlistFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case VEHICLE_SHORT_LIST_START: return getVehicleShortListStart( state, action );
        case VEHICLE_SHORT_LIST_SUCCESS: return getVehicleShortListSuccess( state, action );
        case VEHICLE_SHORT_LIST_FAILURE: return getVehicleShortListFail( state, action );
       
        case ADD_REMOVE_VEHICLE_SHORTLIST_START: return addRemoveShortlistStart( state, action );
        case ADD_REMOVE_VEHICLE_SHORTLIST_SUCCESS: return addRemoveShortlistSuccess( state, action );
        case ADD_REMOVE_VEHICLE_SHORTLIST_FAILURE: return addRemoveShortlistFail( state, action );
        default:
            return state;
    }
};

export default reducer;
