import {
    GET_CAR_DETAIL_START,
    GET_CAR_DETAIL_SUCCESS,
    GET_CAR_DETAIL_FAILURE,

    GET_BIKE_DETAIL_START,
    GET_BIKE_DETAIL_SUCCESS,
    GET_BIKE_DETAIL_FAILURE,

    GET_VARIANT_FILTER_LIST_START,
    GET_VARIANT_FILTER_LIST_SUCCESS,
    GET_VARIANT_FILTER_LIST_FAILURE,

    GET_VARIANT_SPECIFICATION_LIST_START,
    GET_VARIANT_SPECIFICATION_LIST_SUCCESS,
    GET_VARIANT_SPECIFICATION_LIST_FAILURE,

    GET_VARIANT_SPECIFICATION_LIST_BIKE_START,
    GET_VARIANT_SPECIFICATION_LIST_BIKE_SUCCESS,
    GET_VARIANT_SPECIFICATION_LIST_BIKE_FAILURE,

    GET_CITY_WISE_PRODUCT_PRICE_START,
    GET_CITY_WISE_PRODUCT_PRICE_SUCCESS,
    GET_CITY_WISE_PRODUCT_PRICE_FAILURE 

} from 'actions/ProductDetailActions/listTypes';
import { updateObject } from '../utility';

// getCompareListReducer  
const initialState = {
    vehicleDetailData: [],
    allDetails:[],
    exteriorImages:[],
    interiorImages:[],
    images:[],
    keyFeatures:[],
    key_features_list:[],
    priceBreakeUp:[],
    specifications:[],
    variantList : [],
    videoLinks:[],
    vehicleImages:[],
    filterVariantData:[],
    filterVariantCheck:[],
    variantSpecsData : [],
    variantFeatureData :[],
    // Bike initial state start
    allDetailsBike : [],
    exteriorImagesBike : [],
    colorImagesDataBike : [],
    videoLinksBike : [],
    variantListBike : [],
    vehicleImagesBike : [],
    keyFeaturesBike : [],
    keyFeaturesListBike : [],
    specificationsBike : [],
    priceBreakeUpBike : [],
    cityWisePrice:[],
    // Bike initial state end
    error: null,
    loadingVehicleDetailData: false,
    loadingBikeDetailData: false,
    loadingFilterVariantData: false,
    loadingVariantSpecsData: false
};
 
// Car Detail Reducers Function
const getCarDetailStart = ( state ) => {
    return updateObject( state, { error: null, loadingVehicleDetailData: true } );
};

const getCarDetailSuccess = ( state, action ) => {
    return updateObject( state, {
        vehicleDetailData : action.data,
        allDetails : action.data.details,
        exteriorImages : action.data.exterior_images,
        interiorImages : action.data.interior_images,
        colorImagesData : action.data.color_images,
        videoLinks : action.data.videoURL,
        variantList : action.data.varient_list,
        filterVariantData: action.data.varient_list,
        vehicleImages : action.data.images,
        keyFeatures : action.data.keyFeatures,
        keyFeaturesList : action.data.key_features_list,
        specifications : action.data.key_specifications,
        priceBreakeUp : action.data.price_brakeup,
        error: null,
        loadingVehicleDetailData: false
    } );
};

const getCarDetailFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingVehicleDetailData: false
    } );
};

// Bike Detail Reducers Function
const getBikeDetailStart = ( state ) => {
    return updateObject( state, { error: null, loadingBikeDetailData: true } );
};

const getBikeDetailSuccess = ( state, action ) => {
    return updateObject( state, {
        vehicleDetailData: action.data,
        allDetailsBike : action.data.details,
        exteriorImagesBike : action.data.exterior_images,
        colorImagesDataBike : action.data.color_images,
        videoLinksBike : action.data.videoURL,
        variantListBike : action.data.varient_list,
        vehicleImagesBike : action.data.images,
        keyFeaturesBike : action.data.keyFeatures,
        keyFeaturesListBike : action.data.key_features_list,
        specificationsBike : action.data.key_specifications,
        priceBreakeUpBike : action.data.price_brakeup,
        error: null,
        loadingBikeDetailData: false
    } );
};

const getBikeDetailFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingBikeDetailData: false
    } );
};

// Vehicle Variant Filter Reducers Function
const getVehicleFilterListStart = ( state ) => {
    return updateObject( state, { error: null, loadingFilterVariantData: true } );
};

const getVehicleFilterListSuccess = ( state, action ) => {
    return updateObject( state, {
        filterVariantData : action.data.varient_list,
        error: null,
        loadingFilterVariantData: false
    } );
};

const getVehicleFilterListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingFilterVariantData: false
    } );
};

// Vehicle (CAR) Specification (MODAL OnChange API) Reducers Function
const getVariantSpecsListStart = ( state ) => {
    return updateObject( state, { error: null, loadingVariantSpecsData: true } );
};

const getVariantSpecsListSuccess = ( state, action ) => {
    return updateObject( state, {
        specifications : action.data.key_specifications,
        keyFeaturesList : action.data.key_features_list,
        error: null,
        loadingVariantSpecsData: false
    } );
};

const getVariantSpecsListFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingVariantSpecsData: false
    } );
};


// Vehicle (BIKE) Specification (MODAL OnChange API) Reducers Function
const getVariantSpecsListBikeStart = ( state ) => {
    return updateObject( state, { error: null, loadingVariantSpecsData: true } );
};

const getVariantSpecsListBikeSuccess = ( state, action ) => {
    return updateObject( state, {
        specificationsBike : action.data.key_specifications,
        keyFeaturesList : action.data.key_features_list,
        error: null,
        loadingVariantSpecsData: false
    } );
};

const getVariantSpecsListBikeFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingVariantSpecsData: false
    } );
};

// PRODUCT CITY-WISE PRICE (MODAL OnChange API) Reducers Function
const getCityWiseProductPriceStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getCityWiseProductPriceSuccess = ( state, action ) => {
    return updateObject( state, {
        cityWisePrice : action.data,
        error: null,
        loading: false
    } );
};

const getCityWiseProductPriceFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_CAR_DETAIL_START: return getCarDetailStart( state, action );
        case GET_CAR_DETAIL_SUCCESS: return getCarDetailSuccess( state, action );
        case GET_CAR_DETAIL_FAILURE: return getCarDetailFail( state, action );

        case GET_BIKE_DETAIL_START: return getBikeDetailStart( state, action );
        case GET_BIKE_DETAIL_SUCCESS: return getBikeDetailSuccess( state, action );
        case GET_BIKE_DETAIL_FAILURE: return getBikeDetailFail( state, action );

        case GET_VARIANT_FILTER_LIST_START: return getVehicleFilterListStart( state, action );
        case GET_VARIANT_FILTER_LIST_SUCCESS: return getVehicleFilterListSuccess( state, action );
        case GET_VARIANT_FILTER_LIST_FAILURE: return getVehicleFilterListFail( state, action );

        case GET_VARIANT_SPECIFICATION_LIST_START: return getVariantSpecsListStart( state, action );
        case GET_VARIANT_SPECIFICATION_LIST_SUCCESS: return getVariantSpecsListSuccess( state, action );
        case GET_VARIANT_SPECIFICATION_LIST_FAILURE: return getVariantSpecsListFail( state, action );

        case GET_VARIANT_SPECIFICATION_LIST_BIKE_START: return getVariantSpecsListBikeStart( state, action );
        case GET_VARIANT_SPECIFICATION_LIST_BIKE_SUCCESS: return getVariantSpecsListBikeSuccess( state, action );
        case GET_VARIANT_SPECIFICATION_LIST_BIKE_FAILURE: return getVariantSpecsListBikeFail( state, action );

        case GET_CITY_WISE_PRODUCT_PRICE_START: return getCityWiseProductPriceStart( state, action );
        case GET_CITY_WISE_PRODUCT_PRICE_SUCCESS: return getCityWiseProductPriceSuccess( state, action );
        case GET_CITY_WISE_PRODUCT_PRICE_FAILURE: return getCityWiseProductPriceFail( state, action );
        
        default:
            return state;
    }
};

export default reducer;
