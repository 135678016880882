import {
    GET_ON_ROAD_PRICE_VARIANT_START,
    GET_ON_ROAD_PRICE_VARIANT_SUCCESS,
    GET_ON_ROAD_PRICE_VARIANT_FAILURE
} from 'actions/GetOnRoadPriceActions/listTypes';
import { updateObject } from '../utility';

const initialState = {
    VarientORPData: [],
    VariantIndividualPriceData : [],
    VariantCorporatePriceData :[],
    error: null,
    loadingVORPData: false,
};

const getOnRoadPriceVariantStart = (state) => {
    return updateObject(state, { error: null, loadingVORPData: true });
};

const getOnRoadPriceVariantSuccess = (state, action) => {
    return updateObject(state, {
        VarientORPData: action.data,
        VariantIndividualPriceData: action.data.individual,
        VariantCorporatePriceData: action.data.corporate,
        error: null,
        loadingVORPData: false
    });
};

const getOnRoadPriceVariantFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loadingVORPData: false
    });
};

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ON_ROAD_PRICE_VARIANT_START: return getOnRoadPriceVariantStart(state, action);
        case GET_ON_ROAD_PRICE_VARIANT_SUCCESS: return getOnRoadPriceVariantSuccess(state, action);
        case GET_ON_ROAD_PRICE_VARIANT_FAILURE: return getOnRoadPriceVariantFail(state, action);

        default:
            return state;
    }
};

export default reducer;