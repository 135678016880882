// POPULAR VEHICLE LIST CASE CONSTANTS START
export const GET_PAPULAR_CAR_LIST_START = 'GET_PAPULAR_CAR_LIST_START';
export const GET_PAPULAR_CAR_LIST_SUCCESS = 'GET_PAPULAR_CAR_LIST_SUCCESS';
export const GET_PAPULAR_CAR_LIST_FAILURE = 'GET_PAPULAR_CAR_LIST_FAILURE';

export const GET_PAPULAR_BIKE_LIST_START = 'GET_PAPULAR_BIKE_LIST_START';
export const GET_PAPULAR_BIKE_LIST_SUCCESS = 'GET_PAPULAR_BIKE_LIST_SUCCESS';
export const GET_PAPULAR_BIKE_LIST_FAILURE = 'GET_PAPULAR_BIKE_LIST_FAILURE';
// POPULAR VEHICLE LIST CASE CONSTANTS END
// 
// NEWLY LAUNCHED VEHICLE LIST CASE CONSTANTS START
export const GET_NEWLY_LAUNCHED_CAR_LIST_START = 'GET_NEWLY_LAUNCHED_CAR_LIST_START';
export const GET_NEWLY_LAUNCHED_CAR_LIST_SUCCESS = 'GET_NEWLY_LAUNCHED_CAR_LIST_SUCCESS';
export const GET_NEWLY_LAUNCHED_CAR_LIST_FAILURE = 'GET_NEWLY_LAUNCHED_CAR_LIST_FAILURE';

export const GET_NEWLY_LAUNCHED_BIKE_LIST_START = 'GET_NEWLY_LAUNCHED_BIKE_LIST_START';
export const GET_NEWLY_LAUNCHED_BIKE_LIST_SUCCESS = 'GET_NEWLY_LAUNCHED_BIKE_LIST_SUCCESS';
export const GET_NEWLY_LAUNCHED_BIKE_LIST_FAILURE = 'GET_NEWLY_LAUNCHED_BIKE_LIST_FAILURE';
// NEWLY LAUNCHED VEHICLE LIST CASE CONSTANTS END
// 
// COMPARE VEHICLE LIST CASE CONSTANTS START
export const GET_COMPARE_VEHICLE_CAR_LIST_START = 'GET_COMPARE_VEHICLE_CAR_LIST_START';
export const GET_COMPARE_VEHICLE_CAR_LIST_SUCCESS = 'GET_COMPARE_VEHICLE_CAR_LIST_SUCCESS';
export const GET_COMPARE_VEHICLE_CAR_LIST_FAILURE = 'GET_COMPARE_VEHICLE_CAR_LIST_FAILURE';

export const GET_COMPARE_VEHICLE_BIKE_LIST_START = 'GET_COMPARE_VEHICLE_BIKE_LIST_START';
export const GET_COMPARE_VEHICLE_BIKE_LIST_SUCCESS = 'GET_COMPARE_VEHICLE_BIKE_LIST_SUCCESS';
export const GET_COMPARE_VEHICLE_BIKE_LIST_FAILURE = 'GET_COMPARE_VEHICLE_BIKE_LIST_FAILURE';
// COMPARE VEHICLE LIST CASE CONSTANTS END


// BRAND + MODAL VEHICLE LIST CASE CONSTANTS START
export const GET_BRAND_MODAL_CAR_LIST_START = 'GET_BRAND_MODAL_CAR_LIST_START';
export const GET_BRAND_MODAL_CAR_LIST_SUCCESS = 'GET_BRAND_MODAL_CAR_LIST_SUCCESS';
export const GET_BRAND_MODAL_CAR_LIST_FAILURE = 'GET_BRAND_MODAL_CAR_LIST_FAILURE';

export const GET_BRAND_MODAL_BIKE_LIST_START = 'GET_BRAND_MODAL_BIKE_LIST_START';
export const GET_BRAND_MODAL_BIKE_LIST_SUCCESS = 'GET_BRAND_MODAL_BIKE_LIST_SUCCESS';
export const GET_BRAND_MODAL_BIKE_LIST_FAILURE = 'GET_BRAND_MODAL_BIKE_LIST_FAILURE';
// BRAND + MODAL VEHICLE LIST CASE CONSTANTS END

// VEHICLE BRAND LIST CASE CONSTANTS START
export const GET_CAR_BRAND_LIST_START = 'GET_CAR_BRAND_LIST_START';
export const GET_CAR_BRAND_LIST_SUCCESS = 'GET_CAR_BRAND_LIST_SUCCESS';
export const GET_CAR_BRAND_LIST_FAILURE = 'GET_CAR_BRAND_LIST_FAILURE';

export const GET_BIKE_BRAND_LIST_START = 'GET_BIKE_BRAND_LIST_START';
export const GET_BIKE_BRAND_LIST_SUCCESS = 'GET_BIKE_BRAND_LIST_SUCCESS';
export const GET_BIKE_BRAND_LIST_FAILURE = 'GET_BIKE_BRAND_LIST_FAILURE';
// VEHICLE BRAND LIST CASE CONSTANTS END

// CITY LIST CASE CONSTANTS START
export const GET_CITY_LIST_START= "GET_CITY_LIST_START";
export const GET_CITY_LIST_SUCCESS= "GET_CITY_LIST_SUCCESS";
export const GET_CITY_LIST_FAILURE="GET_CITY_LIST_FAILURE";

export const UPDATE_MY_LOCATION= "UPDATE_MY_LOCATION";
// CITY LIST CASE CONSTANTS END

// SIMILAR VEHICLE LIST CASE CONSTANTS START
export const GET_SIMILAR_CAR_LIST_START = 'GET_SIMILAR_CAR_LIST_START';
export const GET_SIMILAR_CAR_LIST_SUCCESS = 'GET_SIMILAR_CAR_LIST_SUCCESS';
export const GET_SIMILAR_CAR_LIST_FAILURE = 'GET_SIMILAR_CAR_LIST_FAILURE';

export const GET_SIMILAR_BIKE_LIST_START = 'GET_SIMILAR_BIKE_LIST_START';
export const GET_SIMILAR_BIKE_LIST_SUCCESS = 'GET_SIMILAR_BIKE_LIST_SUCCESS';
export const GET_SIMILAR_BIKE_LIST_FAILURE = 'GET_SIMILAR_BIKE_LIST_FAILURE';
// SIMILAR VEHICLE LIST CASE CONSTANTS END


// RECENTLY VEHICLE LIST CASE CONSTANTS START
export const GET_RECENTLY_VIEWED_LIST_START = 'GET_RECENTLY_VIEWED_LIST_START';
export const GET_RECENTLY_VIEWED_LIST_SUCCESS = 'GET_RECENTLY_VIEWED_LIST_SUCCESS';
export const GET_RECENTLY_VIEWED_LIST_FAILURE = 'GET_RECENTLY_VIEWED_LIST_FAILURE';
export const GET_RECENTLY_VIEWED_LIST_UPDATE = 'GET_RECENTLY_VIEWED_LIST_UPDATE';
// RECENTLY VEHICLE LIST CASE CONSTANTS END

// HOME FILTERS LIST CASE CONSTANTS START
export const GET_PRODUCT_FILTERS_LIST_START = 'GET_PRODUCT_FILTERS_LIST_START';
export const GET_PRODUCT_FILTERS_LIST_SUCCESS = 'GET_PRODUCT_FILTERS_LIST_SUCCESS';
export const GET_PRODUCT_FILTERS_LIST_FAILURE = 'GET_PRODUCT_FILTERS_LIST_FAILURE';
// HOME FILTERS LIST CASE CONSTANTS END

// SEARCH DATA LIST CASE CONSTANTS START
export const GET_SEARCH_DATA_LIST_START = 'GET_SEARCH_DATA_LIST_START';
export const GET_SEARCH_DATA_LIST_SUCCESS = 'GET_SEARCH_DATA_LIST_SUCCESS';
export const GET_SEARCH_DATA_LIST_FAILURE = 'GET_SEARCH_DATA_LIST_FAILURE';
// SEARCH DATA LIST CASE CONSTANTS END

// SEARCH DATA LIST CASE CONSTANTS START
export const GET_SEARCH_LIST_START = 'GET_SEARCH_LIST_START';
export const GET_SEARCH_LIST_SUCCESS = 'GET_SEARCH_LIST_SUCCESS';
export const GET_SEARCH_LIST_FAILURE = 'GET_SEARCH_LIST_FAILURE';
// SEARCH DATA LIST CASE CONSTANTS END
