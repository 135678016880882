// VEHICLE BRAND LIST CONSTANTS START
export const GET_CAR_DETAIL_START = 'GET_CAR_DETAIL_START';
export const GET_CAR_DETAIL_SUCCESS = 'GET_CAR_DETAIL_SUCCESS';
export const GET_CAR_DETAIL_FAILURE = 'GET_CAR_DETAIL_FAILURE';

export const GET_BIKE_DETAIL_START = 'GET_BIKE_DETAIL_START';
export const GET_BIKE_DETAIL_SUCCESS = 'GET_BIKE_DETAIL_SUCCESS';
export const GET_BIKE_DETAIL_FAILURE = 'GET_BIKE_DETAIL_FAILURE';
// VEHICLE BRAND LIST CONSTANTS END

// VEHICLE GET_VARIANT_FILTER_ LIST CONSTANTS START
export const GET_VARIANT_FILTER_LIST_START = 'GET_VARIANT_FILTER_LIST_START';
export const GET_VARIANT_FILTER_LIST_SUCCESS = 'GET_VARIANT_FILTER_LIST_SUCCESS';
export const GET_VARIANT_FILTER_LIST_FAILURE = 'GET_VARIANT_FILTER_LIST_FAILURE';
// VEHICLE GET_VARIANT_FILTER_LIST CONSTANTS END

// VEHICLE GET_VARIANT_SPECIFICATION_LIST_START LIST CONSTANTS START
export const GET_VARIANT_SPECIFICATION_LIST_START = 'GET_VARIANT_SPECIFICATION_LIST_START';
export const GET_VARIANT_SPECIFICATION_LIST_SUCCESS = 'GET_VARIANT_SPECIFICATION_LIST_SUCCESS';
export const GET_VARIANT_SPECIFICATION_LIST_FAILURE = 'GET_VARIANT_SPECIFICATION_LIST_FAILURE';
// VEHICLE GET_VARIANT_SPECIFICATION_LIST_START CONSTANTS END

// VEHICLE GET_VARIANT_FEATURE_LIST_START LIST CONSTANTS START
export const GET_VARIANT_FEATURE_LIST_START = 'GET_VARIANT_FEATURE_LIST_START';
export const GET_VARIANT_FEATURE_LIST_SUCCESS = 'GET_VARIANT_FEATURE_LIST_SUCCESS';
export const GET_VARIANT_FEATURE_LIST_FAILURE = 'GET_VARIANT_FEATURE_LIST_FAILURE';
// VEHICLE GET_VARIANT_FEATURE_LIST_START CONSTANTS END


// VEHICLE GET_VARIANT_SPECIFICATION_LIST_BIKE_ LIST CONSTANTS START
export const GET_VARIANT_SPECIFICATION_LIST_BIKE_START = 'GET_VARIANT_SPECIFICATION_LIST_BIKE_START';
export const GET_VARIANT_SPECIFICATION_LIST_BIKE_SUCCESS = 'GET_VARIANT_SPECIFICATION_LIST_BIKE_SUCCESS';
export const GET_VARIANT_SPECIFICATION_LIST_BIKE_FAILURE = 'GET_VARIANT_SPECIFICATION_LIST_BIKE_FAILURE';
// VEHICLE GET_VARIANT_SPECIFICATION_LIST_BIKE_ CONSTANTS END

// VEHICLE GET_CITY_WISE_PRODUCT_PRICE CONSTANTS START
export const GET_CITY_WISE_PRODUCT_PRICE_START = 'GET_CITY_WISE_PRODUCT_PRICE_START';
export const GET_CITY_WISE_PRODUCT_PRICE_SUCCESS = 'GET_CITY_WISE_PRODUCT_PRICE_SUCCESS';
export const GET_CITY_WISE_PRODUCT_PRICE_FAILURE = 'GET_CITY_WISE_PRODUCT_PRICE_FAILURE';
// VEHICLE GET_CITY_WISE_PRODUCT_PRICE CONSTANTS END

        
