// FILTER: USED VEHICLE PRODUCT DETAILS CONSTANTS START
export const GET_USED_CAR_DETAILS_START = 'GET_USED_CAR_DETAILS_START';
export const GET_USED_CAR_DETAILS_SUCCESS = 'GET_USED_CAR_DETAILS_SUCCESS';
export const GET_USED_CAR_DETAILS_UPDATE = 'GET_USED_CAR_DETAILS_UPDATE';
export const GET_USED_CAR_DETAILS_FAILURE = 'GET_USED_CAR_DETAILS_FAILURE';

export const GET_USED_BIKE_DETAILS_START = 'GET_USED_BIKE_DETAILS_START';
export const GET_USED_BIKE_DETAILS_SUCCESS = 'GET_USED_BIKE_DETAILS_SUCCESS';
export const GET_USED_BIKE_DETAILS_UPDATE = 'GET_USED_BIKE_DETAILS_UPDATE';
export const GET_USED_BIKE_DETAILS_FAILURE = 'GET_USED_BIKE_DETAILS_FAILURE';
// FILTER: USED VEHICLE PRODUCT DETAILS CONSTANTS END
