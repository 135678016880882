import {
        GET_NEW_DEALER_INFO_START,
        GET_NEW_DEALER_INFO_SUCCESS,
        GET_NEW_DEALER_INFO_FAILURE
} from 'actions/ProductDealerActions/listTypes';
import { updateObject } from '../utility';

const initialState = {
    newDealerInfoData :[],
    error: null,
    loading: false,
};

// VEHICLE SHORTLIST REDUCER FUNCTION
const getNewDealerInfoStart = ( state ) => {
    return updateObject( state,{ 
                error: null,
                loading: true
            });
};

const getNewDealerInfoSuccess = ( state, action ) => {
    return updateObject( state, {
        newDealerInfoData : action.data,
        error: null,
        loading: false
    } );
};

const getNewDealerInfoFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_NEW_DEALER_INFO_START: return getNewDealerInfoStart( state, action );
        case GET_NEW_DEALER_INFO_SUCCESS: return getNewDealerInfoSuccess( state, action );
        case GET_NEW_DEALER_INFO_FAILURE: return getNewDealerInfoFail( state, action );

        default:
            return state;
    }
};

export default reducer;
