// VEHICLE GET ON ROAD PRICE API LIST CONSTANTS 
export const GET_ON_ROAD_PRICE_START = 'GET_ON_ROAD_PRICE_START';
export const GET_ON_ROAD_PRICE_SUCCESS = 'GET_ON_ROAD_PRICE_SUCCESS';
export const GET_ON_ROAD_PRICE_FAILURE = 'GET_ON_ROAD_PRICE_FAILURE';
// VEHICLE GET ON ROAD PRICE API LIST CONSTANTS END

// VEHICLE ON ROAD PRICE ACCORDING TO VARIANT API LIST CONSTANTS 
export const GET_ON_ROAD_PRICE_VARIANT_START = 'GET_ON_ROAD_PRICE_VARIANT_START';
export const GET_ON_ROAD_PRICE_VARIANT_SUCCESS = 'GET_ON_ROAD_PRICE_VARIANT_SUCCESS';
export const GET_ON_ROAD_PRICE_VARIANT_FAILURE = 'GET_ON_ROAD_PRICE_VARIANT_FAILURE';
// VEHICLE ON ROAD PRICE ACCORDING TO VARIANT API LIST CONSTANTS END

// export const GET_BRAND_DETAIL_BIKE_LIST_START = 'GET_BRAND_DETAIL_BIKE_LIST_START';
// export const GET_BRAND_DETAIL_BIKE_LIST_SUCCESS = 'GET_BRAND_DETAIL_BIKE_LIST_SUCCESS';
// export const GET_BRAND_DETAIL_BIKE_LIST_FAILURE = 'GET_BRAND_DETAIL_BIKE_LIST_FAILURE';
// // VEHICLE BRAND DETAIL "ONGOING | UPCOMING | EXPIRED" API LIST CONSTANTS END