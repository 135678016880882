import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import getPopularListReducer from './GetCommonReducer/getPopularListReducer';
import getBrandModalListReducer from './GetCommonReducer/getBrandModalListReducer';
import getNewlyLaunchedListReducer from './GetCommonReducer/getNewlyLaunchedListReducer';
import getVehicleBrandListReducer from './GetCommonReducer/getVehicleBrandListReducer';
import getCompareListReducer from './GetCommonReducer/getCompareListReducer';
import vehicleDetailReducer from './VehicleDetailReducer/vehicleDetailReducer';
import getCityListReducer from './GetCommonReducer/getCityListReducer';
import getSearchListReducer from './GetCommonReducer/getSearchListReducer';
import getSimilarSearchListReducer from './GetCommonReducer/getSimilarListReducer';
import getSimilarListReducer from './GetCommonReducer/getSimilarListReducer';
import getRecentlyViewedListReducer from './GetCommonReducer/getRecentlyViewedListReducer';
import usedVehicleReducer from './UsedVehicleReducer/usedVehicleReducer';
import vehicleBrandReducer from './VehicleBrandReducer/vehicleBrandReducer';
import vehicleBrandDetailReducer from './VehicleBrandReducer/vehicleBrandDetailReducer';
import onRoadPriceReducer from './GetOnRoadPriceReducer/onRoadPriceReducer';
import variantORPReducer from './GetOnRoadPriceReducer/variantORPReducer';
import sellWithUsVehicleReducer from './SellWithUsVehicleReducer/sellWithUsVehicleReducer';
import vehicleShortListReducer from './VehicleShortListReducer/vehicleShortListReducer';
import compareVehicleReducer from './CompareVehicleReducer/compareVehicleReducer';
import compareDetailVehicleReducer from './CompareVehicleReducer/compareDetailVehicleReducer';
import searchUsedVehicleReducer from './SearchUsedVehicleReducer/searchUsedVehicleReducer';
import filterUsedVehicleReducer from './FilterVehicleReducer/filterUsedVehicleReducer';
import homeFilterReducer from './GetCommonReducer/homeFilterReducer';
import getDealerInfoReducer from './ProductDealerReducer/getDealerInfoReducer';
import getNewDealerInfoReducer from './ProductDealerReducer/getNewDealerInfoReducer';
import getWriteReviewReducer from './ProductDealerReducer/getWriteReviewReducer';
import getAllReviewReducer from './ProductDealerReducer/getAllReviewReducer';
import getAssistanceReducer from './ProductDealerReducer/getAssistanceReducer';
import searchBrandReducer from './GetCommonReducer/searchBrandReducer';

const appReducer = combineReducers({
    routing : routerReducer,
    filterOptionsData : homeFilterReducer,
    popularList: getPopularListReducer,
    brandModalList: getBrandModalListReducer,
    newlyLaunchedList: getNewlyLaunchedListReducer,
    compareVehicleList: getCompareListReducer,
    vehicleBrandList: getVehicleBrandListReducer,
    vehicleDetailData: vehicleDetailReducer,
    cityListData: getCityListReducer,
    commonSearchListData: getSearchListReducer,
    searchListData: getSimilarSearchListReducer,
    similarVehicleListData: getSimilarListReducer,
    usedVehicleListData: usedVehicleReducer,
    recentlyViewedList: getRecentlyViewedListReducer,
    vehicleBrandData : vehicleBrandReducer,
    brandDetailData: vehicleBrandDetailReducer,
    onRoadPriceListData: onRoadPriceReducer,
    variantORPData: variantORPReducer,
    sellWithUsData: sellWithUsVehicleReducer,
    VehicleShortList: vehicleShortListReducer,
    compareVehicleData: compareVehicleReducer,
    compareDetailVehicleData: compareDetailVehicleReducer,
    searchUsedVehicleData: searchUsedVehicleReducer,
    filterUsedVehicleData: filterUsedVehicleReducer,
    dealerInfoData: getDealerInfoReducer,
    newDealerInfoData: getNewDealerInfoReducer,
    writeReviewData: getWriteReviewReducer,
    allReviewData: getAllReviewReducer,
    assistanceResponseData: getAssistanceReducer,
    searchedBrands: searchBrandReducer,
    form: formReducer,
})

const rootReducer = (state, action) => {
    if (action.type=== 'RESET_ALL_DATA') {
        state={
            auth : state.auth
        }
    }
    return appReducer(state,action)
}

export default rootReducer