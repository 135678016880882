import {
        GET_USED_CAR_DETAILS_START,
        GET_USED_CAR_DETAILS_SUCCESS,
        GET_USED_CAR_DETAILS_FAILURE,

        GET_USED_BIKE_DETAILS_START,
        GET_USED_BIKE_DETAILS_SUCCESS,
        GET_USED_BIKE_DETAILS_FAILURE
} from 'actions/FilterVehicleActions/listTypes';
import { updateObject } from '../utility';

// getCompareListReducer  
const initialState = {
    filterUsedVehicleData:[],
    filterKeyFeatures:[],
    filterKeySpecs:[],
    filterDealerBenefits:[],
    filterProductImage:[],
    error: null,
    loading: false
};
 
// Used Car List Reducers Function
const getUsedCarDetailsStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

// filterKeyFeatures,filterProductImage
const getUsedCarDetailsSuccess = ( state, action ) => {
    return updateObject( state, {
        filterUsedVehicleData:action.data,
        filterKeyFeatures:action.data.keyFeatures,
        filterKeySpecs:action.data.specifications,
        filterDealerBenefits:action.data.dealer_benefits,
        filterProductImage: action.data.images,
        error: null,
        loading: false
    } );
};

const getUsedCarDetailsFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// Used Bike List Reducers Function
const getUsedBikeDetailsStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const getUsedBikeDetailsSuccess = ( state, action ) => {
    return updateObject( state, {
        filterUsedVehicleData:action.data,
        filterKeyFeatures:action.data.keyFeatures,
        filterKeySpecs:action.data.specifications,
        filterDealerBenefits:action.data.dealer_benefits,
        filterProductImage: action.data.images,
        error: null,
        loading: false
    } );
};

const getUsedBikeDetailsFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_USED_CAR_DETAILS_START: return getUsedCarDetailsStart( state, action );
        case GET_USED_CAR_DETAILS_SUCCESS: return getUsedCarDetailsSuccess( state, action );
        case GET_USED_CAR_DETAILS_FAILURE: return getUsedCarDetailsFail( state, action );

        case GET_USED_BIKE_DETAILS_START: return getUsedBikeDetailsStart( state, action );
        case GET_USED_BIKE_DETAILS_SUCCESS: return getUsedBikeDetailsSuccess( state, action );
        case GET_USED_BIKE_DETAILS_FAILURE: return getUsedBikeDetailsFail( state, action );

        default:
            return state;
    }
};

export default reducer;
