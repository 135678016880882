import {
        GET_ON_ROAD_PRICE_START,
        GET_ON_ROAD_PRICE_SUCCESS,
        GET_ON_ROAD_PRICE_FAILURE
} from 'actions/GetOnRoadPriceActions/listTypes';
import { updateObject } from '../utility';

// getCompareListReducer  
const initialState = {
    onRoadPriceData: [],
    ORPDetailsData:[],
    varientListData:[],
    error: null,
    loadingORPData: false,
};
 
// Car Detail Reducers Function
const getOnRoadPriceStart = ( state ) => {
    return updateObject( state, { error: null, loadingORPData: true } );
};

const getOnRoadPriceSuccess = ( state, action ) => {
    return updateObject( state, {
        onRoadPriceData : action.data,
        ORPDetailsData : action.data.details,
        varientListData : action.data.varient_list,
        error: null,
        loadingORPData: false
    } );
};

const getOnRoadPriceFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loadingORPData: false
    } );
};

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
    
        case GET_ON_ROAD_PRICE_START: return getOnRoadPriceStart( state, action );
        case GET_ON_ROAD_PRICE_SUCCESS: return getOnRoadPriceSuccess( state, action );
        case GET_ON_ROAD_PRICE_FAILURE: return getOnRoadPriceFail( state, action );

        default:
            return state;
    }
};

export default reducer;
