import {
    GET_RECENTLY_VIEWED_LIST_START,
    GET_RECENTLY_VIEWED_LIST_SUCCESS,
    GET_RECENTLY_VIEWED_LIST_FAILURE,
    GET_RECENTLY_VIEWED_LIST_UPDATE
} from 'actions/GetCommonActions/listTypes';
import { updateObject } from '../utility';


const initialState = {
    recentlyViewedList: [],
    error: null,
    recentlyViewedListLoading: false
};
 
// SIMILAR CAR LIST FUNCTION
const getRecentlyViewedListStart = ( state ) => {
    return updateObject( state, 
        { 
            error: null,
            recentlyViewedListLoading: true
        } );
};

const getRecentlyViewedListSuccess = ( state, action ) => {
    return updateObject( state, {
        recentlyViewedList: action.data,
        error: null,
        recentlyViewedListLoading: false
    } );
};

const getRecentlyViewedListFailure = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        recentlyViewedListLoading: false
    } );
};

const getRecentlyViewedListUpdate =( state, action ) =>{
    let allData= state.recentlyViewedList;
    allData[action.data].wishlistStatus=allData[action.data].wishlistStatus===0?1:0
    return updateObject(state, {
        recentlyViewedList: allData,
        error: null,
        recentlyViewedListLoading: false
    });
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_RECENTLY_VIEWED_LIST_START: return getRecentlyViewedListStart( state, action );
        case GET_RECENTLY_VIEWED_LIST_SUCCESS: return getRecentlyViewedListSuccess( state, action );
        case GET_RECENTLY_VIEWED_LIST_FAILURE: return getRecentlyViewedListFailure( state, action );
        case GET_RECENTLY_VIEWED_LIST_UPDATE: return getRecentlyViewedListUpdate( state, action );

        default:
            return state;
    }
};

export default reducer;
