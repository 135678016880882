// SEARCH USED VEHICLE LIST CONSTANTS START
export const GET_SEARCH_USED_CAR_LIST_START = 'GET_SEARCH_USED_CAR_LIST_START';
export const GET_SEARCH_USED_CAR_LIST_SUCCESS = 'GET_SEARCH_USED_CAR_LIST_SUCCESS';
export const GET_SEARCH_USED_CAR_LIST_UPDATE = 'GET_SEARCH_USED_CAR_LIST_UPDATE';
export const GET_SEARCH_USED_CAR_LIST_FAILURE = 'GET_SEARCH_USED_CAR_LIST_FAILURE';

export const GET_SEARCH_USED_BIKE_LIST_START = 'GET_SEARCH_USED_BIKE_LIST_START';
export const GET_SEARCH_USED_BIKE_LIST_SUCCESS = 'GET_SEARCH_USED_BIKE_LIST_SUCCESS';
export const GET_SEARCH_USED_BIKE_LIST_UPDATE = 'GET_SEARCH_USED_BIKE_LIST_UPDATE';
export const GET_SEARCH_USED_BIKE_LIST_FAILURE = 'GET_SEARCH_USED_BIKE_LIST_FAILURE';
// SEARCH USED VEHICLE LIST CONSTANTS END
