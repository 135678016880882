

// VEHICLE SHORTLIST START 
export const VEHICLE_SHORT_LIST_START = 'VEHICLE_SHORT_LIST_START';
export const VEHICLE_SHORT_LIST_SUCCESS = 'VEHICLE_SHORT_LIST_SUCCESS';
export const VEHICLE_SHORT_LIST_FAILURE = 'VEHICLE_SHORT_LIST_FAILURE';
// VEHICLE SHORTLIST END 

// ADD REMOVE VEHICLE SHORTLIST START
export const ADD_REMOVE_VEHICLE_SHORTLIST_START = 'ADD_REMOVE_VEHICLE_SHORTLIST_START';
export const ADD_REMOVE_VEHICLE_SHORTLIST_SUCCESS = 'ADD_REMOVE_VEHICLE_SHORTLIST_SUCCESS';
export const ADD_REMOVE_VEHICLE_SHORTLIST_FAILURE = 'ADD_REMOVE_VEHICLE_SHORTLIST_FAILURE';
// ADD REMOVE VEHICLE SHORTLIST END