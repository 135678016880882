import {
    SELL_WITH_US_VEHICLE_START,
    SELL_WITH_US_VEHICLE_SUCCESS,
    SELL_WITH_US_VEHICLE_FAILURE
} from 'actions/SellWithUsVehicleActions/listTypes';

import { updateObject } from '../utility';
 
const initialState = {
    sellWithUsData: [],
    error: null,
    loading: false
};
 
// SELL WITH US: Reducers Function
const sellWithUsVehicleStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const sellWithUsVehicleSuccess = ( state, action ) => {
    return updateObject( state, {
        sellWithUsData : action.data,
        error: null,
        loading: false
    } );
};

const sellWithUsVehicleFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case SELL_WITH_US_VEHICLE_START: return sellWithUsVehicleStart( state, action );
        case SELL_WITH_US_VEHICLE_SUCCESS: return sellWithUsVehicleSuccess( state, action );
        case SELL_WITH_US_VEHICLE_FAILURE: return sellWithUsVehicleFail( state, action );

        default:
            return state;
    }
};

export default reducer;
