import {
    GET_SEARCH_DATA_LIST_START,
    GET_SEARCH_DATA_LIST_SUCCESS,
    GET_SEARCH_DATA_LIST_FAILURE
} from 'actions/GetCommonActions/listTypes';
import { updateObject } from '../utility';

const initialState = {
    searchBrandData: [],
    error: null,
    loading: false
};
 
const getSearchDataListStart=( state )=>{
    return updateObject( state , { 
        error: null, 
        loading: true  
    });
}
const getSearchDataListSuccess = ( state, action ) =>{
    return updateObject(state, {
        searchBrandData: action.data,
        error: null,
        loading: false
    });
}
const getSearchDataListFail = (state, action)=>{
    return updateObject (state, {
        error: action.error,
        loading: false
    });
}

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_SEARCH_DATA_LIST_START : return getSearchDataListStart( state, action );
        case GET_SEARCH_DATA_LIST_SUCCESS : return getSearchDataListSuccess( state, action );
        case GET_SEARCH_DATA_LIST_FAILURE : return getSearchDataListFail( state, action );
        
        default:
            return state;
    }
};

export default reducer;
