import {
    WRITE_REVIEW_START,
    WRITE_REVIEW_SUCCESS,
    WRITE_REVIEW_FAILURE
} from 'actions/ProductDealerActions/listTypes';
import { updateObject } from '../utility';

const initialState = {
    writeReviewData :[],
    error: null,
    loading: false,
};

// VEHICLE SHORTLIST REDUCER FUNCTION
const writeReviewStart = ( state ) => {
    return updateObject( state,{ 
                error: null,
                loading: true
            });
};

const writeReviewSuccess = ( state, action ) => {
    return updateObject( state, {
        writeReviewData : action.data,
        error: null,
        loading: false
    } );
};

const writeReviewFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        
        case WRITE_REVIEW_START: return writeReviewStart( state, action );
        case WRITE_REVIEW_SUCCESS: return writeReviewSuccess( state, action );
        case WRITE_REVIEW_FAILURE: return writeReviewFail( state, action );

        default:
            return state;
    }
};

export default reducer;
