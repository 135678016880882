import {
        GET_PRODUCT_FILTERS_LIST_START,
        GET_PRODUCT_FILTERS_LIST_SUCCESS,
        GET_PRODUCT_FILTERS_LIST_FAILURE
} from 'actions/GetCommonActions/listTypes';

import { updateObject } from '../utility';

const initialState = {
    filterData: [],
    filterBrands: [],
    filterBudget: [],
    filterFuel: [],
    filterTransmission: [],
    filterKilometer: [],
    filterLocation: [],
    error: null,
    loading: false
};
 
const getProductFilterListStart=( state )=>{
    return updateObject( state , { 
        error: null, 
        loading: true  
    });
}
const getProductFilterListSuccess = ( state, action ) =>{
    return updateObject(state, {
        filterData: action.data,
        filterBrands: action.data.brands,
        filterBudget: action.data.budget,
        filterFuel: action.data.fuel,
        filterTransmission: action.data.transmission,
        filterKilometer: action.data.kilometer,
        filterLocation: action.data.location,
        error: null,
        loading: false
    });
}
const getProductFilterListFail = (state, action)=>{
    return updateObject (state, {
        error: action.error,
        loading: false
    });
}

// This is a Reducer, its an example of 'Pure Function'
// It accepts Previous STATE and ACTION 
// and returns Updates STATE. 
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case GET_PRODUCT_FILTERS_LIST_START : return getProductFilterListStart( state, action );
        case GET_PRODUCT_FILTERS_LIST_SUCCESS : return getProductFilterListSuccess( state, action );
        case GET_PRODUCT_FILTERS_LIST_FAILURE : return getProductFilterListFail( state, action );
        
        default:
            return state;
    }
};

export default reducer;
