import React from 'react';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import PublicRoute from 'hoc/routeHoc/publicRoute';
import Loader from 'shared/Loader';
// import PrivateRoute from 'hoc/routeHoc/privateRoute';
// import { getUserDetails } from 'utils/helper';

const Loading = () => {
    // https://motobazaar.in/
    return (
        <Loader />
    );
}
// const LoginPage = React.lazy(() => import('containers/AuthPage/LoginPage')); Dealer
const DashboardPage = React.lazy(() => import('containers/DashboardPage'));
const FilterVehiclePage = React.lazy(() => import('containers/FilterVehiclePage'));
const VehicleListingPage = React.lazy(() => import('containers/VehicleListingPage'));
const UsedVehicleListingPage = React.lazy(() => import('containers/UsedVehicleListingPage'));
const CompareVehiclePage = React.lazy(() => import('containers/CompareVehiclePage'));
const CompareVehicleDetailPage = React.lazy(() => import('containers/CompareVehicleDetailPage'));
const DealerDetailPage = React.lazy(() => import('containers/DealerDetailPage'));
const DealerListPage = React.lazy(() => import('containers/DealerListingPage'));
const DealerInfoPage = React.lazy(() => import('containers/DealerInfoPage'));
const OnRoadPricePage = React.lazy(() => import('containers/OnRoadPricePage'));
const OnRoadPriceDetailPage = React.lazy(() => import('containers/OnRoadPriceDetailPage'));
const SellWithUsPage = React.lazy(() => import('containers/SellWithUsPage'));
const VehicleShortlistPage = React.lazy(() => import('containers/VehicleShortlistPage'));
const VehicleBrandPage = React.lazy(() => import('containers/VehicleBrandPage'));
const VehicleBrandDetailPage = React.lazy(() => import('containers/VehicleBrandDetailPage'));
const VehicleDescriptionPage = React.lazy(() => import('containers/VehicleDescriptionPage'));
const AboutUsPage = React.lazy(() => import('containers/AboutUsPage'));
const LastEnquiryPage = React.lazy(() => import('containers/LastEnquiryPage'));
const TandCPage = React.lazy(() => import('containers/TandCPage'));
const PolicyPage = React.lazy(() => import('containers/PolicyPage'));
const Page404 = React.lazy(() => import('containers/Page404'));

const App = (props) => {
    // const user = getUserDetails().token;
    const navigate = useNavigate();

    return (
        <React.Suspense fallback={<Loading />}>
            <Routes>
                {/* DashboardPage: Home page or landing page component */}
                <Route path="/"
                    element={
                        <PublicRoute user={false} >
                            <DashboardPage navigate={navigate} name='Dashboard' />
                        </PublicRoute>
                    }
                />
                {/* FilterVehiclePage:  */}
                {/* <Route path="/old-car-filter/:vehicleType/:cityName/:stateName" */}
                <Route path="/:vehicleType/:cityName/:stateName"
                    element={
                        <PublicRoute user={false} >
                            <FilterVehiclePage navigate={navigate} name='Used Vehicle Filter' />
                        </PublicRoute>
                    }
                />
                {/* VehicleListingPage: Shows the detail of selected vehicle */}
                {/* <Route path="/car-listing/:vehicleType/:productId/:variantId/:cityName/:stateName" */}
                <Route path="/:vehicleType/:productName/:productVariantName/:cityName/:stateName"
                    element={
                        <PublicRoute user={false}>
                            <VehicleListingPage navigate={navigate} name='Vehicle Listing' />
                        </PublicRoute>
                    }
                />

                {/* UsedVehicleListingPage: Shows the detail of selected used vehicle */}
                {/* <Route path="/car-listing-used/:userId/:vehicleType/:productName/:productId" */}
                <Route path="/:userId/:vehicleType/:productName/:productId"
                    element={
                        <PublicRoute user={false}>
                            <UsedVehicleListingPage navigate={navigate} name='Used Car Listing' />
                        </PublicRoute>
                    }
                />

                {/* CompareVehiclePage: Shows the list of all the vehicle added for comparison */}
                <Route path='/compare-cars/:vehicleType'
                    element={
                        <PublicRoute user={false}>
                            <CompareVehiclePage navigate={navigate} name='Compare Car' />
                        </PublicRoute>
                    }
                />
                <Route path='/compare-cars/:vehicleType/:productOne'
                    element={<PublicRoute user={false}>
                        <CompareVehiclePage navigate={navigate} name="Compare Car" />
                    </PublicRoute>}
                />
                {/* CompareVehicleDetailPage: Shows the details of all the vehicle added for comparison */}
                {/* <Route path='/compare-cars-detail/:vehicleType/:city/:productOne?/:productTwo?/productThree/productFour' */}
                {/* <Route path='/compare-cars-detail/:vehicleType?'
                    element={<PublicRoute user={false}>
                            <CompareVehicleDetailPage navigate={navigate} name="Compare Car Detail" />
                        </PublicRoute>
                    }
                /> */}
                <Route path='/compare-cars-detail/:vehicleType'
                    element={<PublicRoute user={false}>
                        <CompareVehicleDetailPage navigate={navigate} name="Compare Car Detail" />
                    </PublicRoute>}
                />
                <Route path='/compare-cars-detail/:vehicleType/:city'
                    element={<PublicRoute user={false}>
                        <CompareVehicleDetailPage navigate={navigate} name="Compare Car Detail" />
                    </PublicRoute>}
                />
                <Route path='/compare-cars-detail/:vehicleType/:city/:productOne'
                    element={<PublicRoute user={false}>
                        <CompareVehicleDetailPage navigate={navigate} name="Compare Car Detail" />
                    </PublicRoute>}
                />
                <Route path='/compare-cars-detail/:vehicleType/:city/:productOne/:productTwo'
                    element={<PublicRoute user={false}>
                        <CompareVehicleDetailPage navigate={navigate} name="Compare Car Detail" />
                    </PublicRoute>}
                />
                <Route path='/compare-cars-detail/:vehicleType/:city/:productOne/:productTwo/:productThree'
                    element={<PublicRoute user={false}>
                        <CompareVehicleDetailPage navigate={navigate} name="Compare Car Detail" />
                    </PublicRoute>}
                />
                <Route path='/compare-cars-detail/:vehicleType/:city/:productOne/:productTwo/:productThree/:productFour'
                    element={<PublicRoute user={false}>
                        <CompareVehicleDetailPage navigate={navigate} name="Compare Car Detail" />
                    </PublicRoute>}
                />
                {/* DealerDetailPage: Shows the Single DEALER with all the products available @ Motobazaar" */}
                <Route path='/dealer-details/:userId/:vehicleType/:dealerName/:city/:state'
                    element={
                        <PublicRoute user={false}>
                            <DealerDetailPage navigate={navigate} name="Dealer Detail" />
                        </PublicRoute>
                    }
                />
                {/* DealerInfoPage: Shows the list of all the "Dealer @ Motobazaar" */}
                <Route path='/dealers'
                    element={
                        <PublicRoute user={false}>
                            <DealerListPage navigate={navigate} name="Dealer" />
                        </PublicRoute>
                    }
                />
                {/* DealerInfoPage: Shows the detailed information of the "Specific Dealer"*/}
                {/* <Route path='/city-dealers' */}
                <Route path='/:city/:vehicleType/dealers/:brandId'
                    element={
                        <PublicRoute user={false}>
                            <DealerInfoPage navigate={navigate} name="City Dealer" />
                        </PublicRoute>
                    }
                />
                {/* OnRoadPricePage: Shows the minimum Price Break-up of the "Specific Vehicle"*/}
                <Route path='/on-road-price'
                    element={
                        <PublicRoute user={false}>
                            <OnRoadPricePage navigate={navigate} name="On Road Price" />
                        </PublicRoute>
                    }
                />
                {/* OnRoadPriceDetailPage: Shows the Price Break-up Details of the Specific Vehicle*/}
                {/* <Route path='/price/:vehicleType/:cityname/:statename/:brandID/:modelID' */}
                <Route path='/price/:vehicleType/:brandName/:modelName/:cityname/:statename'
                    element={
                        <PublicRoute user={false}>
                            <OnRoadPriceDetailPage navigate={navigate} name="Price" />
                        </PublicRoute>
                    }
                />
                {/* SellWithUsPage: Want to become a Dealer@ Motobazaar*/}
                <Route path='/sell-with-us'
                    element={
                        <PublicRoute user={false}>
                            <SellWithUsPage navigate={navigate} name="Sell With Us" />
                        </PublicRoute>
                    }
                />
                {/* VehicleShortlistPage: Vehicle Shortlisted by Customer/User Component */}
                <Route path='/shortlist/:userID/:cityName/:stateName'
                    element={
                        <PublicRoute user={false}>
                            <VehicleShortlistPage navigate={navigate} name="Shortlist" />
                        </PublicRoute>
                    }
                />
                {/* VehicleBrandPage: Vehicle View All Brand Component, includes ONGOING | UPCOMING | EXPIRED sections */}
                <Route path='/brand/:vehicleType/:cityName/:stateName/'
                    element={
                        <PublicRoute user={false}>
                            <VehicleBrandPage navigate={navigate} name="Brand" />
                        </PublicRoute>
                    }
                />
                {/* VehicleBrandDetailPage: Vehicle Brand Description Component */}
                {/* <Route path='/brand-cars/:tabHeaderType/:brandName/:vehicleType/:cityName/:stateName/:brandId/' */}
                <Route path='/:tabHeaderType/:brandName/:vehicleType/:cityName/:stateName/:brandId/'
                    element={
                        <PublicRoute user={false}>
                            <VehicleBrandDetailPage navigate={navigate} name="Ford Cars" />
                        </PublicRoute>
                    }
                />
                {/* Vehicle Description Component */}
                <Route path='/ecosport'
                    element={
                        <PublicRoute user={false}>
                            <VehicleDescriptionPage navigate={navigate} name="EcoSport" />
                        </PublicRoute>
                    }
                />
                {/* Motobazaar: About Us Component */}
                <Route path='/about-us'
                    element={
                        <PublicRoute user={false}>
                            <AboutUsPage navigate={navigate} name="About Us" />
                        </PublicRoute>
                    }
                />
                {/* Customer Last Enquiry Component */}
                <Route path='/last-enquiry'
                    element={
                        <PublicRoute user={false}>
                            <LastEnquiryPage navigate={navigate} name="Last Enquiry" />
                        </PublicRoute>
                    }
                />
                {/* Terms and Conditions Component */}
                <Route path='/terms-condition'
                    element={
                        <PublicRoute user={false}>
                            <TandCPage navigate={navigate} name="Terms and Condition" />
                        </PublicRoute>
                    }
                />
                {/* Privacy Policy Component */}
                <Route path='/privacy-policy'
                    element={
                        <PublicRoute user={false}>
                            <PolicyPage navigate={navigate} name="Privacy Policy" />
                        </PublicRoute>
                    }
                />
                {/* <Route path="/login" element={<PublicRoute user={user}><LoginPage name='login' /></PublicRoute>}/> */}
                <Route path="/404" name="Page 404" element={<Page404 />} />
                <Route path="*" name="Page 404" element={<Navigate to={'/404'} />} />
            </Routes>

        </React.Suspense>
    );
}
// App.propTypes = {
//     getAffiliateRefFun: PropTypes.func,
// };

export default App;